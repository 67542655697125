export const environment = {
  firebase: {
    projectId: 'adios-b4bd2',
    appId: '1:820702138597:web:980f25e21bd27d0d82c40c',
    databaseURL: 'https://adios-b4bd2.firebaseio.com',
    storageBucket: 'adios-b4bd2.appspot.com',
    locationId: 'asia-south1',
    apiKey: 'AIzaSyDh9O_JMl81gdUxD6kX_q2a6sO_5ZejIu4',
    authDomain: 'adios-b4bd2.firebaseapp.com',
    messagingSenderId: '820702138597',
    measurementId: 'G-KS0LCJK0BB',
  },
  production: true,
  domain: "https://adios-world.azurewebsites.net/",
  website: "https://adiosworld.com/",
  stripe_key: "pk_live_51IjlcWSEIRI6P3kdLlwjuGp3L7iWEggZyKXfBDojGO59xz6N0YcVgsrnswTy8irvA7gbFvj4kYuW0UM61EaaIw9300a56NZZGJ",
  storagedomain: "https://adiosworld.blob.core.windows.net/",
  sas: "?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacuptfx&se=2023-07-08T11:01:36Z&st=2021-07-08T03:01:36Z&spr=https,http&sig=tT0XGOg48mUP4%2Fbw2aEDnaViadljQZV%2BAvzo17%2Flg9w%3D",

  recaptcha: {
    siteKey: '6LcjY0kgAAAAAMbf5uNaQHUz3Fb1ePBcio597sNA',
    v2: "6LfTJk8gAAAAAGlBSZz_auJ0FFZVGrRWIxm4_k-9",
  },
  googleProvider: '820702138597-d6hb4fra3h9pd1bbao4m7pfqkjsmgk94.apps.googleusercontent.com',
  category: "core"
};
