import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _authChangeSub = new Subject<boolean>()
    public authChanged = this._authChangeSub.asObservable();

    defaultCurrency: any;
    licenses: any;
    user: any;
    finalizedScenario: any;
    invitedUser: any;
    isloggedin = false;
    isUserVerified = false;
    subscriptionLimits: {
        IsSubscribed: boolean;
        LimitGrants: boolean;
        LimitScenarios: boolean;
        NoOfGrants: number;
        NoOfScenarios: number;
    } = {
            IsSubscribed: false,
            LimitGrants: true,
            LimitScenarios: true,
            NoOfGrants: 1,
            NoOfScenarios: 1,
        };
    email: string = '';
    schemes: any = [];
    organisation: any;

    constructor() {
        this.getUser();
    }

    public getToken(): string {
        var token = localStorage.getItem('soto');
        if (token == null) {
            return '';
        }
        return token;
    }

    public setToken(token: string | null) {
        if (token == null) {
            localStorage.removeItem('soto');
        } else {
            localStorage.setItem('soto', token);
        }
    }

    public getUser() { }

    public setUser(user: any) {
        this.user = user;
        this.sendAuthStateChangeNotification(true);
    }

    public setSchemes(schemes: any) {
        this.schemes = schemes;
    }

    public logout() {
        localStorage.removeItem('soto');
        this.licenses = undefined;
        this.user = undefined;
        this.finalizedScenario = undefined;
        this.invitedUser = undefined;
        this.isloggedin = false;
        this.schemes = [];
        localStorage.clear();
        localStorage.removeItem("token");
        this.sendAuthStateChangeNotification(false);
    }
    public sendAuthStateChangeNotification = (isAuthenticated: boolean) => {
        this._authChangeSub.next(isAuthenticated);
    }

    public getUserName = (): string => {
        var name = "";
        if (this.user) {
            name = this.user.FirstName;
        }
        return name;
    }

    public getAvatar = (): string => {
        var avatar = null;
        if (this.user && this.user.Avatar) {
            avatar = this.user.Avatar;
        }
        return avatar;
      }

    public getFullName = (): string => {
        var name = "";
        if (this.user) {
            name = this.user.FirstName + " " + this.user.LastName;
        }
        return name;
    }

    
}
