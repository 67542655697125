// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { UserService } from './user.service';
// import { Observable } from 'rxjs/Observable';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public userService: UserService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    var d = new Date()
    var timezoneOffset = d.getTimezoneOffset();
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.userService.getToken()}`,
        offset: timezoneOffset.toString()
      },
      // withCredentials: true
    });
    
    return next.handle(request);
  }
}