import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    //dashboard
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    { path: 'dashboard', loadChildren: () => import('modules/console/console-routing.module').then( m => m.ConsoleRoutingModule ), },
    //auth
    { path: 'auth', loadChildren: () => import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule), },
    
    { path: 'error', loadChildren: () => import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule) },

    //profile
    { path: 'profile', loadChildren: () => import('modules/profile/profile-routing.module').then( m => m.ProfileRoutingModule ), },
    //onboarding
    { path: 'onboarding', loadChildren: () => import('modules/onboarding/onboarding-routing.module').then( m => m.OnboardingRoutingModule), },
    { path: 'verify', loadChildren: () => import('modules/verify/verify-routing.module').then(m => m.VerifyRoutingModule), },
    
    //payment
    { path: 'payment', loadChildren: () => import('modules/payment/payment-routing.module').then(m => m.PaymentRoutingModule) },

    { path: '**', pathMatch: 'full', loadChildren: () => import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule) },
    // { path: '**', redirectTo: '/notfound', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', anchorScrolling: 'enabled', relativeLinkResolution: 'legacy', initialNavigation: 'enabledBlocking' })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
