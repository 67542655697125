import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiService } from 'services/api.service';
import { UserService } from 'services/user.service';
import { MatNativeDateModule } from '@angular/material/core';
import { TokenInterceptor } from 'services/token-interceptor.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule, 
    HttpClientModule, 
    BrowserAnimationsModule, 
    MatNativeDateModule,
    NgbModule, 
  ],
  providers: [ApiService, UserService, 
    // RecaptchaService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: { siteKey: environment.recaptcha.v2 } as RecaptchaSettings,
    // },
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: environment.recaptcha.siteKey,
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
