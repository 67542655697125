import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { UserService } from './user.service';

const median: any = (arr: any) => {
    const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};
@Injectable({
    providedIn: 'root',
})
export class ApiService {
    domain: any;

    apis = {
        validateToken: 'api/Auth/ValidateToken',
        Login: 'api/Auth/Login',
        externalLogin: 'api/Auth/ExternalLogin',
        googleLogin: 'api/Auth/GoogleLogin',
        Register: 'api/Auth/Register',
        ResetPassword: 'api/Auth/ResetPassword',
        ResendVerification: 'api/Auth/ResendVerification',
        VerifyEmail: 'api/Auth/VerifyEmail',
        VerifyResetPassword: 'api/Auth/VerifyResetPassword',
        getPaymentDetails: 'api/Subscription/ProcessApplication/',
        getOnboardingdata: 'api/Setup/GetOnboardingData',

        //Organisation Admin Related
        getPendingInvitations: 'api/Organisation/GetPendingInvitations/',
        deletePendingInvite: 'api/Organisation/DeletePendingInvite/',
        inviteUserInOrganisation: 'api/Organisation/InviteUsersInOrganisation/',

        getOrganisationUsers: 'api/Organisation/GetOrganisationUsers/',
        deleteOrganisationUser: 'api/Organisation/RemoveOrganisationUser/',

        getOrganisationMemberships: 'api/Organisation/GetOrganisationMemberships/',
        getMembershipDetails: 'api/Organisation/GetMembershipDetails/',
        getMembershipOrderPreview: 'api/Organisation/GetMembershipOrderPreview/',
        subscribeMembership: 'api/Organisation/SubscribeMembership/',
        getOrderDetails: 'api/Organisation/GetOrderDetails/',
        addUserInMembership: 'api/Organisation/AddUserInMembership/',
        removeUserFromMembership: 'api/Organisation/RemoveUserFromMembership/',

        validateOrder: 'api/payment/ValidateOrder',
        





        getDashboard: 'api/Dashboard/GetDashboard',
        getPublicPrograms: 'api/Program/GetPublicPrograms',

        getUserDetails: 'api/Dashboard/GetUserDetails',

        getPrograms: 'api/Program/GetPrograms',
        getProgram: 'api/Program/GetProgram/',
        getPublicProgram: 'api/Program/GetPublicProgram/',

        getApplicationData: 'api/Batches/GetApplications',
        SetupUser: 'api/Setup/SetupUser',
        GetBatches: 'api/Batches/GetBatches/',
        ChangePassword: 'api/User/ChangePassword',
        NewPassword: 'api/User/SetPassword',

        //User
        enrollBatch: 'api/User/JoinBatch/',
        createOrganisation: 'api/User/CreateOrganisation/',
        // getOrganisationUsers: 'api/User/GetOrganisationUsers/',
        removeUserFromOrganisation: 'api/User/RemoveUserFromOrganisation/',
        removeInviteFromOrganisation: 'api/User/RemoveInviteFromOrganisation/',
        getSubscriptions: 'api/User/GetSubscriptions/',
        cancelSubscription: 'api/User/CancelSubscription/',
        saveBasicProfile: 'api/User/SaveBasicProfile/',

        //Adioscope        
        getPublicAdioscope: 'api/Adioscope/GetPublic',
        getPersonalAdioscope: 'api/Adioscope/GetPersonal',
        getAdioscopeMeta: 'api/Adioscope/getAdioscopeMeta',
        addSituation: 'api/Adioscope/AddSituation',
        getSituation: 'api/Adioscope/GetSituation/',
        viewSituation: 'api/Adioscope/GetSituationDetail/',
        getSituationAnalysis: 'api/Adioscope/GetSituationAnalysis/',
        changeSituationPrivacy: 'api/Adioscope/ChangeSituationPrivacy/',
        updateSituationDescription: 'api/Adioscope/UpdateSituationDescription/',
        updateSituationClarity: 'api/Adioscope/UpdateSituationClarity/',
        updateSituationPrincipleTrigger: 'api/Adioscope/UpdateSituationPrincipleTrigger',
        updateSituationPrincipleMap: 'api/Adioscope/UpdateSituationPrincipleMap',
        changeSituationState: 'api/Adioscope/ChangeSituationState/',

        //Venus Match
        getVenusDetails: 'api/Venus/GetDetails',

        //Forum (Ask Us)
        getForumQuestions: 'api/Forum/GetQuestions',
        getForumQuestion: 'api/Forum/GetQuestion/',
        saveForumQuestion: 'api/Forum/SaveQuestion/',

        //Program Workspace
        getProgramSpace: 'api/Workspace/GetProgramSpace/',
        getProgramContent: 'api/Workspace/GetProgramContent/',
        streamAudioContent: 'api/Workspace/StreamAudioContent/',
        streamVideoContent: 'api/Workspace/StreamVideoContent/',
        getProfileQuestions: 'api/Workspace/GetProfileQuestions/',
        updateProfileQuestionMeta: 'api/Workspace/UpdateProfileQuestionMeta/',
        getContentQuestions: 'api/Workspace/GetContentQuestions/',
        updateContentQuestionMeta: 'api/Workspace/UpdateContentQuestionMeta/',
        contentCompleted: 'api/Workspace/ContentCompleted/',

        //Conversations
        getConversation: 'api/Conversation/GetConversation/',
        sendMessage: 'api/Conversation/SendMessage/',
        getMessages: 'api/Conversation/GetMessages/',
        updateLastReadOn: 'api/Conversation/UpdateLastReadOn/',

        //Web
        getProgramDetails: 'api/Web/GetProgramDetails/',
        getSignupData: 'api/Web/GetSignupData',
        submitCategoryForm: 'api/Web/SubmitCategoryForm/',
        joinVenusPartnerDiscoveryWaitlist: 'api/Web/JoinVenusPartnerDiscoveryWaitlist',

        //Payment
        // getOrderDetails: 'api/payment/GetDetails',

        //Journal
        getNotes: 'api/Journal/GetNotes',
        getUserJournal: 'api/Journal/GetJournal',
        addToJournal: 'api/Journal/AddToJournal',
        addUserTag: 'api/Journal/AddUserTag',

        //Get Categories
        checkCategoryMembership: 'api/Category/CheckCategoryMembership/',
        getCategoryMembership: 'api/Category/GetMembership/',
        getCategorySubscription: 'api/Category/GetCategorySubscription/',
        // subscribeMembership: 'api/Category/SubscribeMembership/',
        getCategoryWorkspace: 'api/Category/GetCategoryWorkspace/',
        getProfileGroups: 'api/Category/GetProfileGroups/',
        joinCategoryBatch: 'api/Category/JoinCategoryBatch/',
        getOrganisationMembership: 'api/Category/GetOrganisationMembership/',
        getCategoryUpcomingCohorts: 'api/Category/GetUpcomingCohorts/',
        getCategoryProgram: 'api/Category/GetCategoryProgram/',
        getCategoryContent: 'api/Category/GetCategoryContent/',

        RecommendUsers: 'api/User/RecommendUsers',

        getCenter: 'api/Center/GetCenter/',
        getCenterProgram: 'api/Center/GetCenterProgram/',
        SubmitApplication: 'api/Batches/SubmitApplication',
        getBatchSchedule: 'api/Program/GetBatchSchedule/',
        getBatchReport: 'api/Report/GetBatchReport/',
        uploadContentFile: 'api/Program/UploadContentFile/',
        // streamAudioContent: 'api/Program/StreamAudioContent/',
        // streamVideoContent: 'api/Program/StreamVideoContent/',
        downloadContentFile: 'api/Program/DownloadContentFile/',
        deleteContentFile: 'api/Program/DeleteContentFile/',
        addValueToAssignment: 'api/Program/AddValueToAssignment/',
        addValueToDateAssignment: 'api/Program/AddValueToDateAssignment/',
        getParticipantAssignmentTopics: 'api/Program/GetParticipantAssignmentTopics/',
        addSituationInProgram: 'api/Program/AddSituationInProgram/',
        getSituationDetail: 'api/Program/GetSituationDetail/',
        addAnalysisInSituation: 'api/Program/AddAnalysisInSituation/',
        getChangeAssignmentStatus: 'api/Program/ChangeAssignmentStatus/',
        getContemplationItems: 'api/Program/GetContemplationItems',
        startAdiosession: 'api/Program/StartAdiosession',
        joinBatch: 'api/Batches/JoinBatch',
        joinProgram: 'api/Batches/JoinProgram/',
        getAdiosession: 'api/Program/GetAdiosession/',
        startContemplation: 'api/Program/StartContemplation/',
        stopContemplation: 'api/Program/StopContemplation/',
        Subscribe: 'api/Subcription/CreateCheckout',
        ValidateOrder: 'api/Subcription/ValidateOrder',
        getAcapSubscriptions: 'api/Acap/GetSubscriptions',
        subscribeToAcap: 'api/Acap/Subscribe',
        validateAcapSubscription: 'api/Acap/ValidateAcapSubscription',

        //Essay Notes
        sendEssayNotes: 'api/Notification/SendEssayNote',

        //Vision Planner
        getVisionPlanner: 'api/VisionPlanner/GetPlanner/',
        setupPlanner: 'api/VisionPlanner/SetupPlanner/',
        previewPlanner: 'api/VisionPlanner/PreviewPlanner',
        saveDimensions: 'api/VisionPlanner/SaveDimensions/',
        getVersionDetails: 'api/VisionPlanner/GetVersionDetails/',
        saveDimension: 'api/VisionPlanner/SaveDimension/',
        saveArea: 'api/VisionPlanner/SaveArea/',
        saveTask: 'api/VisionPlanner/SaveTask/',
        removeTask: 'api/VisionPlanner/RemoveTask/',
        removeArea: 'api/VisionPlanner/RemoveArea/',
        removeDimension: 'api/VisionPlanner/RemoveDimension/',
        JVZooValidate: 'api/JVZoo/Validate',


        //Trainers
        getTrainerPrograms: 'api/Trainer/GetPrograms',
        getTrainerBatchParticipants: 'api/Trainer/GetBatchParticipants/',
        getTrainerEndBatchWeek: 'api/Trainer/EndWeek/',
        createNewAssignmentTopic: 'api/Trainer/NewAssignmentTopic/',
        addAssignmentsToBatch: 'api/Trainer/AddAssignmentsToBatch/',
        publishAssignmentsToBatch: 'api/Trainer/PublishAssignmentsToBatch/',
        getTrainerChangeAssignmentStatus: 'api/Trainer/ChangeAssignmentStatus/',


        //Admin
        addBatch: 'api/Admin/AddBatch',
        getProgramList: 'api/Trainer/GetProgramList',

    };

    constructor(private user: UserService, private http: HttpClient, private router: Router, private title: Title, private meta: Meta) {
        this.domain = environment.domain;
    }

    getDataFromUrl(url: string) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(url, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }


    postDataToUrl(url: string, data: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(url, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = e.error.Message;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    deleteDataFromUrl(url: string) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .delete(url, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getDashboard() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getDashboard, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getAcapSubscriptions() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getAcapSubscriptions, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getOnboardingData() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getOnboardingdata, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getSignupData() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getSignupData, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    submitCategoryForm(data: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.submitCategoryForm, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    joinVenusPartnerDiscoveryWaitlist(data: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.joinVenusPartnerDiscoveryWaitlist, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getProgramSchedule(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getPrograms, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getBatchSchedule(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getBatchSchedule + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getBatchReport(id: number, programParticipantId: number, istrainer?: boolean) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            if (programParticipantId) {
                params = "?pp=" + programParticipantId;
            }
            if (istrainer) {
                params += "&t=" + istrainer;
            }
            this.http
                .get(this.domain + this.apis.getBatchReport + id + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getSituation(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getSituation + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    viewSituation(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.viewSituation + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getSituationAnalysis(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getSituationAnalysis + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getSituationDetails(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getSituationDetail + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getAdiosession(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getAdiosession + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getContemplationItems() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getContemplationItems, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getUserJournal(last_note?: any, content_id?: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = [];
            if (last_note != null) {
                params.push("lm=" + last_note);
            }
            if (content_id != null) {
                params.push("cId=" + content_id);
            }

            var p = "";
            if (params.length > 0) {
                p = "?" + params.join('&');
            }
            this.http
                .get(this.domain + this.apis.getNotes + p, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addToJournal(note: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.addToJournal, note, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    startAdiosession(session: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.startAdiosession, session, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    startContemplation(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.startContemplation + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    stopContemplation(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.stopContemplation + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addUserTag(tag: string) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.addUserTag, {
                    Label: tag
                }, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getAdioscopeMeta() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
                observer: 'response'
            };
            this.http
                .get(this.domain + this.apis.getAdioscopeMeta, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r, response: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getPublicAdioscope(param: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
                observer: 'response'
            };
            this.http
                .get(this.domain + this.apis.getPublicAdioscope + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r, response: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getPersonalAdioscope(param: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getPersonalAdioscope + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getPrograms() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getPrograms, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getUserDetails() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getUserDetails, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getPublicPrograms() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getPublicPrograms, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getProgram(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getProgram + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getProgramDetails(alias: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getProgramDetails + alias, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getPublicProgram(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getPublicProgram + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCenterDetails(centerRef: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCenter + '?c=' + centerRef, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCenterProgram(centerProgramId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCenterProgram + centerProgramId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    enrollBatch(code: any, trial?: boolean, mmk?: any, sp?: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var params = "?";
            var param: any = [];
            if (mmk) {
                param.push("mmk=" + mmk);
            }
            if (sp) {
                param.push("sp=" + sp);
            } else {
                // param.push("sp="+true);
            }

            if (trial) {
                param.push("trial=" + trial);
            }
            if (param.length > 0) {
                params = "?" + param.join("&");
            }

            this.http
                .get(this.domain + this.apis.enrollBatch + code + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg: msg });
                });
        });
    }

    joinBatch(code: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.joinBatch + '?c=' + code, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    joinProgram(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.joinProgram + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getApplications() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getApplicationData, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getPaymentDetails(id: any) {
        return new Promise((res, rej) => {
            var token: string = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.getPaymentDetails + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getBatches(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.GetBatches + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    uploadContentFile(id: number, data: any) {
        return new Promise((res, rej) => {
            let formData = new FormData();

            formData.append('file', data);

            let token = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    Token: token,
                }),
            };

            this.http
                .post(
                    this.domain +
                    this.apis.uploadContentFile +
                    '?c=' + id,
                    formData,
                    httpOptions
                )
                .toPromise()
                .then((r: any) => {
                    ////console.log(r);
                    res({ success: true, data: r });
                })
                .catch(e => {
                    // ////console.log("Error in get", e);
                    if (!e.ok && (e.status < 200 || e.status >= 300)) {
                        if (e.status == 422) {
                            rej({ success: false, data: e, msg: 'helper not found' });
                        } else if (e.status == 401) {
                            this.logout();
                            this.router.navigateByUrl('');
                            rej({ success: false, data: e, msg: 'Session Expired' });
                            // this.logout();
                        } else if (e.status == 404) {
                            rej({
                                success: false,
                                data: e,
                                msg: 'Unable to complete your request',
                            });
                        } else if (e.status == 403) {
                            rej({ success: false, data: e, msg: 'Unauthorized access' });
                        } else if (e.status == 406) {
                            rej({
                                success: false,
                                data: e,
                                msg: 'Unable to update the record as its been linked somewhere',
                            });
                        } else {
                            rej({ success: false, data: e });
                        }
                    }
                });
        });
    }

    contentCompleted(id: number) {

        return new Promise((res, rej) => {
            let token = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.contentCompleted + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    deleteContentFile(id: number) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.deleteContentFile + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addValueToAssignmentTopic(assignmentValue: any) {

        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.addValueToAssignment, assignmentValue, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addValueToDateAssignmentTopic(assignmentValue: any) {

        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.addValueToDateAssignment, assignmentValue, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addSituation(situation: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.addSituation + params, situation, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    updateSituationDescription(situation: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.updateSituationDescription + params, situation, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    updateSituationClarity(situation: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.updateSituationClarity + params, situation, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    updateSituationPrincipleTrigger(situationPrincipleTrigger: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.updateSituationPrincipleTrigger + params, situationPrincipleTrigger, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    updateSituationPrincipleMap(situationPrincipleMap: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.updateSituationPrincipleMap + params, situationPrincipleMap, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addSituationInProgram(situation: any, programId: number, batchId: number, programParticipantId: number) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            this.http
                .post(this.domain + this.apis.addSituationInProgram + params, situation, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addAnalysisInSituation(analysis: any, situationId: number, programParticipantId: number) {

        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?s=" + situationId + "&pp=" + programParticipantId;
            this.http
                .post(this.domain + this.apis.addAnalysisInSituation + params, analysis, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getParticipantTopicValues(topicId: any, programParticipantId: number) {

        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.getParticipantAssignmentTopics + topicId + "?p=" + programParticipantId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    resendVerificationEmail() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.ResendVerification, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }


    sendEssayNote(note: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.sendEssayNotes + params, note, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    verifyEmail(verifyToken: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.VerifyEmail + '?v=' + verifyToken, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    verifyResetPassword(verifyToken: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.VerifyResetPassword + '?v=' + verifyToken, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    checkToken(token: string) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
                withCredentials: true,
            };

            this.http
                .get(this.domain + this.apis.validateToken, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    validateToken(token: string) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
                withCredentials: true,
            };

            this.http
                .get(this.domain + this.apis.validateToken, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    register(user: any, params?: string) {
        return new Promise((res, rej) => {
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            };
            if (params && params != "") {
                params = params + "&category=" + environment.category;
            } else {
                params = "?category=" + environment.category;
            }
            this.http
                .post(this.domain + this.apis.Register + params, user, httpOptions)
                .toPromise()
                .then((r: any) => {
                    this.user.setToken(r.Token);
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    login(user: any) {
        return new Promise((res, rej) => {
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                withCredentials: true,
            };
            
            this.http
                .post(this.domain + this.apis.Login + "?category=" + environment.category, user, httpOptions)
                .toPromise()
                .then((r: any) => {
                    this.user.setToken(r.Auth.Token);
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    externallogin(user: any) {
        return new Promise((res, rej) => {
            var params = "?category=" + environment.category;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                withCredentials: true,
            };
            this.http
                .post(this.domain + this.apis.externalLogin+params, user, httpOptions)
                .toPromise()
                .then((r: any) => {
                    this.user.setToken(r.Auth.Token);
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    subscribeAcap(id: any) {
        return new Promise((res, rej) => {
            var token: string = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.subscribeToAcap + '/' + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    validateAcapSubscription(session_id: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.validateAcapSubscription + '?session_id=' + session_id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    createSubscription(order: any, redirect: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var params = "?redirect=" + redirect;
            this.http
                .post(this.domain + this.apis.Subscribe + params, order, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getOrderDetails(session_id: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.getOrderDetails + '?order_ref=' + session_id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    validateOrder(order_id: string, session_id: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.validateOrder + '?session_id=' + session_id + "&order_id=" + order_id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    validateJvzooOrder(session_id: string, programId: number) {
        return new Promise((res, rej) => {
            var token: string = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.JVZooValidate + '?cbreceipt=' + session_id + '&p=' + programId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    resetPassword(user: any) {
        return new Promise((res, rej) => {
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            };
            this.http
                .post(this.domain + this.apis.ResetPassword, user, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }


    setupUser(user: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var param = "";
            if (user.Code) {
                param = "?c=" + user.Code;
            }

            this.http
                .post(this.domain + this.apis.SetupUser + param, user, httpOptions)
                .toPromise()
                .then((r: any) => {
                    ////console.log(r);
                    this.user.setToken(r.Auth.Token);
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    submitApplication(application: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.SubmitApplication, application, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    logout() {
        localStorage.clear();
        this.user.isloggedin = false;
        this.user.user = undefined;
        this.user.schemes = [];
        this.user.setToken(null);
        this.user.logout();
    }





    //Trainer's Endpoints



    getTrainerPrograms() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerPrograms, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerBatchParticipants(batchId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerBatchParticipants + batchId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerEndBatchWeek(batchId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerEndBatchWeek + batchId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    createNewAssignmentTopic(assignmentTopic: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.createNewAssignmentTopic, assignmentTopic, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addAssignmentsToBatch(assignments: any, batchId: number, programId: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?b=" + batchId + "&p=" + programId;
            this.http
                .post(this.domain + this.apis.addAssignmentsToBatch + params, assignments, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerChangeAssignmentStatus(participantAssignmentId: number, batchId: number, value: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?b=" + batchId + "&p=" + value;
            this.http
                .get(this.domain + this.apis.getTrainerChangeAssignmentStatus + participantAssignmentId + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getChangeAssignmentStatus(participantAssignmentId: number, batchId: number, value: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?b=" + batchId + "&p=" + value;
            this.http
                .get(this.domain + this.apis.getChangeAssignmentStatus + participantAssignmentId + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    publishAssignmentsToBatch(assignments: any, batchId: number, programId: number, programParticipantId?: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?b=" + batchId + "&p=" + programId;
            if (programParticipantId) {
                params += "&pp=" + programParticipantId;
            }
            this.http
                .post(this.domain + this.apis.publishAssignmentsToBatch + params, assignments, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    changeSituationStatus(id: any, state: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?s=" + state;

            this.http
                .get(this.domain + this.apis.changeSituationState + id + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    changeSituationPrivacy(id: any, privacy: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?p=" + privacy;

            this.http
                .get(this.domain + this.apis.changeSituationPrivacy + id + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    //User Apis
    setNewPassword(data: any, token: any) {
        return new Promise((res, rej) => {
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.NewPassword, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    changePassword(data: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.ChangePassword, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    recommendUsers(data: any, program?: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var params = "";
            if(program) {
                params = "?p="+program;
            }

            this.http
                .post(this.domain + this.apis.RecommendUsers + params, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    createOrganisation(data: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.createOrganisation, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveBasicProfile(data: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.saveBasicProfile, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getOrganisationUsers(id: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.getOrganisationUsers + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    inviteUsers(data: any, organisationId: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.inviteUserInOrganisation + "?org_id=" + organisationId, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = e.error.Message;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    removeInviteFromOrganisation(email: any, organisationId: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.removeInviteFromOrganisation + "?org_id=" + organisationId + "&email=" + email, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = e.error.Message;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getSubscriptions() {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.getSubscriptions, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = e.error.Message;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    cancelSubscription(orderId: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.cancelSubscription + orderId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = e.error.Message;
                    rej({ success: false, data: e, msg });
                });
        });
    }



    //Vision Planner
    getPlanner() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .get(this.domain + this.apis.getVisionPlanner + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getVersionDetails(plannerId: number, versionNo: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?v=" + versionNo;
            this.http
                .get(this.domain + this.apis.getVersionDetails + plannerId + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    setupPlanner(planner: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .post(this.domain + this.apis.setupPlanner + params, planner, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    previewPlanner(planner: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .post(this.domain + this.apis.previewPlanner + params, planner, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveDimensions(dimensions: any, plannerId?: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            if (plannerId) {
                params = "?pId=" + plannerId
            }
            this.http
                .post(this.domain + this.apis.saveDimensions + params, dimensions, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveDimension(dimension: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .post(this.domain + this.apis.saveDimension + params, dimension, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveArea(area: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .post(this.domain + this.apis.saveArea + params, area, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveTask(task: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .post(this.domain + this.apis.saveTask + params, task, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    removeTask(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .delete(this.domain + this.apis.removeTask + id + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    removeArea(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .delete(this.domain + this.apis.removeArea + id + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    removeDimension(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .delete(this.domain + this.apis.removeDimension + id + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }




    //Venus Match 
    getVenusDetails() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getVenusDetails, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }



    //Forum (Ask Us) 
    getForumQuestions() {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getForumQuestions, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getForumQuestion(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getForumQuestion + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveForumQuestion(question: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.saveForumQuestion + params, question, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }


    //Program Workspace
    getProgramSpace(batchId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getProgramSpace + batchId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCurriculum(batchId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getProgramContent + batchId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getProfileQuestions(contentId: any, programParticipantId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?pp=" + programParticipantId;
            this.http
                .get(this.domain + this.apis.getProfileQuestions + contentId + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    updateProfileQuestionMeta(answer: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.updateProfileQuestionMeta + params, answer, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getContentQuestions(contentId: any, programParticipantId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?pp=" + programParticipantId;
            this.http
                .get(this.domain + this.apis.getContentQuestions + contentId + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    updateContentQuestionMeta(answer: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.updateContentQuestionMeta + params, answer, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    //Conversation 
    getConversationGroup(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getConversation + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    sendMessage(last_message: string, message: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "?lm=" + last_message;
            this.http
                .post(this.domain + this.apis.sendMessage + params, message, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getMessages(id: number, first_message?: string, last_message?: string) {

        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?";
            if (first_message) {
                params += "fm=" + first_message + "&";
            }
            if (last_message) {
                params += "lm=" + last_message;
            }

            this.http
                .get(this.domain + this.apis.getMessages + id + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    updateLastReadOn(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.updateLastReadOn + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }


    //Category Membership
    checkCategoryMembership(category: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.checkCategoryMembership + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCategoryMembership(category: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCategoryMembership + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCategorySubscription(category: any, offerId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCategorySubscription + offerId + "?category=" + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    subscribeMembership(offerId: any, categoryAlias: any, organisationId?: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var params = "";
            if (organisationId) {
                params = "&org=" + organisationId;
            }

            this.http
                .get(this.domain + this.apis.subscribeMembership + offerId + "?category=" + categoryAlias + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCategoryProgram(category: any, program: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCategoryProgram + program, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCategoryContent(category: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCategoryContent + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCategoryUpcomingCohorts(category: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCategoryUpcomingCohorts + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCategoryWorkspace(category: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCategoryWorkspace + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getProfileGroups(category: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getProfileGroups + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    joinCategoryBatch(batchId: any, trial?: boolean) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var params = "?";
            var param: any = [];

            if (trial) {
                param.push("trial=" + trial);
            }
            if (param.length > 0) {
                params = "?" + param.join("&");
            }

            this.http
                .get(this.domain + this.apis.joinCategoryBatch + batchId + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getOrganisationMembership(organisationId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getOrganisationMembership + organisationId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }


    getOrganisationInvitations(organisationId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.getToken();
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getOrganisationMembership + organisationId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    // Admin Apis
    getProgramsList() {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .get(this.domain + this.apis.getProgramList + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addBatch(batch: any) {
        return new Promise((res, rej) => {
            let token = this.user.getToken();
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.addBatch + params, batch, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }



    //Utilities
    public getDateOffset = () => {
        var offset = new Date().getTimezoneOffset();
        var dateOffset;
        if (offset > 0) {
            dateOffset = "-" + ('0' + Math.floor((offset / 60))).slice(-2) + "" + ('0' + Math.floor((offset % 60)).toFixed(0)).slice(-2);
        } else {
            dateOffset = "+" + ('0' + Math.floor(((offset * -1) / 60)).toFixed(0)).slice(-2) + "" + ('0' + Math.floor(((offset * -1) % 60)).toFixed(0)).slice(-2);
        }

        return dateOffset;
    };

    public getCurrency = () => {
        var offset = new Date().getTimezoneOffset().valueOf();
        if (offset == -330) {
            return "INR";
        } else {
            return "USD";
        }
    }

    updateTitle(title: string) {
        this.title.setTitle(title);
        this.updateOgTitle(title);
    }
    
    updateOgTitle(value: string) {
        this.meta.updateTag({ name: 'og:title', content: value });
    }
    updateOgType(value: string) {
        this.meta.updateTag({ name: 'og:type', content: value });
    }
    updateOgDescription(value: string) {
        this.meta.updateTag({ name: 'og:description', content: value });
    }
    updateOgImage(value: string) {
        this.meta.updateTag({ name: 'og:image', content: value });
    }
    updateOgSiteName(value: string) {
        this.meta.updateTag({ name: 'og:site_name', content: value });
    }
    updateOgUrl(url: string) {
        this.meta.updateTag({ name: 'og:url', content: url })
    }
    updateRobots(value: string) {
        this.meta.updateTag({ name: 'robots', content: value })
    }

    updateDescription(desc: string) {
        this.meta.updateTag({ name: 'description', content: desc });
        this.updateOgDescription(desc);
    }

}
